<template>
  <v-card
    class="pa-4 elevation-0 no-back"
  >
    <div class="hidden-sm-and-down">
    <v-toolbar
      light
      dense
      floating
    >
      <v-autocomplete

        :value="selectedEnseigne"
        hide-details
        prepend-icon="mdi-magnify"
        single-line
        :items="enseignes"
        item-text="name"
        :menu-props="{closeOnContentClick:true}"
        @click="selected"
        @click:clear="clearSelected"
        return-object
        clearable
      >
     <template slot="item" slot-scope="data">
        <v-list-item class="item-customer-border" @click="selected(data.item)">
          <v-list-item-content  class="list-search-customer">
            <v-list-item-title>
              <span>{{ data.item.name }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="enseigne">{{ data.item.enseigne }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
       <template #no-data>
        <v-row no-gutters>
            Aucun magasin
        </v-row>
      </template>
      </v-autocomplete>

      <v-btn icon @click="zoom()">
        <v-icon>mdi-crosshairs-gps</v-icon>
      </v-btn>
    </v-toolbar>
      </div>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
    }
  },
     computed: {
    ...mapState({
      enseignes: state => state.enseignes.all,
      selectedEnseigne: state => state.enseignes.current
    }),
  },
  methods:{
    selected(enseigne) {
      this.$nextTick(() => {
        this.$store.dispatch('setZoom', false)
        this.$store.dispatch('setCurrentEnseigne', enseigne)
      })
    },
    clearSelected() {
      this.$store.dispatch('setCurrentEnseigne', {})
      this.$store.dispatch('setZoom', false)
    },
    zoom() {
      this.$store.dispatch('setZoom', true)
    }
  }
}

</script>
<style lang="scss" scoped>
.no-back {
  background-color:transparent !important;
  z-index: 1000 !important;
}
.enseigne {
  color: grey;
  font-size: 0.9rem;
}
</style>
