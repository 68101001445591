<template>
<div class="inline-enseigne">
   <div v-for="(g, index) in group" :key="'group-'+index" class="item-enseigne">
         <v-radio-group v-model="radioGroup">
          <v-radio :label="g.name" :value="g.stores" @click="setGroup(g)"/>
        </v-radio-group>
      </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import coords from '../assets/coords.json'
export default {
  data () {
    return {
      radioGroup: '',
      active:''
    }
  },
     computed: {
    ...mapState({
      enseignes: state => state.enseignes.all,
      selected: state => state.enseignes.current
    }),
    group() {
      const intermarche = this.enseignes.filter(enseigne => enseigne.enseigne.includes('Intermarché'))
      const superu = this.enseignes.filter(enseigne => enseigne.enseigne.includes('Super U'))
      const leclerc = this.enseignes.filter(enseigne => enseigne.enseigne.includes('E.Leclerc'))
      const indépendant = this.enseignes.filter(enseigne => enseigne.enseigne.includes('Indépendant'))
      const carrefour = this.enseignes.filter(enseigne => enseigne.enseigne.includes('Carrefour'))
      return [
        {name:`Intermarché (${intermarche.length})`, val:'Int', stores: intermarche},
        {name:`Super U (${superu.length})`,val:'Sup',  stores: superu},
        {name:`E.Leclerc (${leclerc.length})`,val:'Lec',  stores: leclerc},
        {name:`Carrefour (${carrefour.length})`,val:'Car',  stores: carrefour},
        {name:`Indépendant (${indépendant.length})`,val:'Ind',  stores: indépendant},
        {name:`Tous (${this.enseignes.length})`,val:'All',  stores: this.enseignes},
        ]
    }
  },
  methods:{
    setGroup(value) {
        this.$store.dispatch('setCentraleType',value.val)
      if (value === this.active) {
        this.active = ''
        this.radioGroup = ''
        this.$store.dispatch('setCoords', coords)
      } else {
        this.$store.dispatch('setCoords', this.radioGroup)
        this.active = value
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.inline-enseigne{
display: inline-flex;
align-items: center;
padding:2px;
}
.item-enseigne {
  margin-left:30px;
  margin-top:15px;
  font-weight: 600;
}
</style>
