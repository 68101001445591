import coords from '../../assets/coords.json'
import centrales from '../../assets/centrales.json'

export default {
    state: {
        all: [],
        current:{},
        coords:coords,
        centrales:centrales,
        zoom:false,
        centraleType:'',
        center: [ 46.990896, 	3.162845 ]
      },
    mutations: {
      setEnseignes (state, enseignes) {
          state.all = enseignes
      },
      setCentrales (state, centrales) {
          state.centrales = centrales
      },
      setCoords (state, coords) {
          state.coords = coords
      },
      setZoom (state, bool) {
          state.zoom = bool
      },
      setCenter (state, array) {
          state.center = array
      },
      setCentraleType (state, bool) {
          state.centraleType = bool
      },
      setCurrentEnseigne (state, enseigne) {
          state.current = enseigne
      },
    },
    actions: {
      setEnseignes ({ commit }, array) {
        commit('setEnseignes', array.sort((a,b) => a.name - b.name))
      },
      setCentrales ({ commit }, array) {
        commit('setCentrales', array.sort((a,b) => a.name - b.name))
      },
      setZoom ({ commit }, bool) {
        commit('setZoom', bool)
      },
      setCenter ({ commit }, array) {
        commit('setCenter', array)
      },
      setCurrentEnseigne ({ commit }, enseigne) {
          commit('setCurrentEnseigne', enseigne)
        },
      setCentraleType ({ commit }, bool) {
          commit('setCentraleType', bool)
      },
      setCoords ({ commit }, coords) {
        commit('setCoords', coords)
      }
    }
}
