<template>
  <l-marker
    :key="marker.id"
    :lat-lng="coords"
    ref="marker"
  >
    <l-icon>
      <img :class="imageMarker === 'hublot' ? 'enseigne-hublot' : 'enseigne-icon'" :src="`/images/${imageMarker}.png`"/>
    </l-icon>
    <l-popup ref="popup" v-if="imageMarker !== 'hublot'">
        <h3>{{marker.name}}</h3>
        <h5>{{marker.enseigne}}</h5>
        </l-popup>
  </l-marker>
</template>

<script>
import { LIcon, LMarker, LPopup } from 'vue2-leaflet'
import { mapState } from 'vuex'
export default {
  components: { LIcon, LMarker, LPopup },
  props: {
    marker: {
      type: Object,
      required: true
    }
  },
  mounted(){
  },
  computed:{
    ...mapState({
      selectedEnseigne: state => state.enseignes.current,
    }),
      coords() {
          if (this.selectedEnseigne.id === this.marker.id) {
          this.$refs.marker.mapObject.openPopup()
     }
          return {lat:this.marker.latitude, lng: this.marker.longitude}
      },
      imageMarker() {
          if (this.marker.enseigne.includes('Intermarché')) {
            return 'intermarche'
          } else if (this.marker.enseigne.includes('E.Leclerc')) {
        return 'leclerc'

          } else if (this.marker.enseigne.includes('Super U')) {
              return 'superu'
      }else if (this.marker.enseigne.includes('Indépendant')) {
              return 'independant'
      }else if (this.marker.enseigne.includes('Carrefour')) {
              return 'carrefour'
      }else if (this.marker.enseigne.includes('Hublot')) {
              return 'hublot'
      }
      return ''
      }

  }
}

</script>

<style>
  .enseigne-icon {
    width: 35px;
  }
  .enseigne-hublot {
    min-width: 250px;
    width: 250px;
    max-width: 250px;
  }
</style>
