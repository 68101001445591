<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Hublot Logo"
          class="shrink mr-2"
          contain
          src="/images/hublot-blanc.png"
          transition="scale-transition"
          width="120"
        />
      </div>
      <div class="ml-4">
        <h2 class="hidden-sm-and-down">Carte des magasins</h2>
        <h3 class="hidden-lg-and-up">Magasins</h3>
      </div>
      <div class="pl-8">
      <Toolbar/>
      <div class="hidden-lg-and-up">

      </div>
      </div>
      <div class="hidden-sm-and-down">
        <EnseigneRadio />
      </div>
      <v-spacer></v-spacer>
      <v-btn
        href="https://hublot.io"
        target="_blank"
        text
      >
        <span class="mr-2">hublot.io</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
<!-- <NavigationDrawer /> -->
    <v-main>
      <Map/>
    </v-main>
  </v-app>
</template>

<script>
import Map from './components/Map';
// import NavigationDrawer from './components/NavigationDrawer';
import Toolbar from './components/Toolbar';
import EnseigneRadio from './components/EnseigneRadio';

export default {
  name: 'App',

  components: {
    Map,
    Toolbar,
    EnseigneRadio,
    // NavigationDrawer
  },

  data: () => ({
    //
  }),
};
</script>
