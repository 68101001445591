import Vue from 'vue'
import Vuex from 'vuex'
import enseignes from './modules/enseignes'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    enseignes,
  }
})


