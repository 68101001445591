import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

const opts = {

  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    themes: {
      light: {
        primary: '#376374',
        secondary: '#8AC3D5',
        danger: '#EF5350',
      },
      options: {
        themeVariations: ['primary', 'secondary', 'danger'],
      },
    },
  },
}
Vue.use(Vuetify)

export default new Vuetify(opts)
